import React from 'react';
import { observer } from 'mobx-react-lite';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import Loading from 'components/common/Loading';

import { createDataDocument, getDataFromDataDocument } from 'utils/db';
import WizardStore from 'state/common/WizardStore';

// import { ActionField } from 'state/common/WizardStore/types' // <-- needs to be actionfieldcomponent not the field since this is used in the template

const Component = ({
  output,
  config,
  store,
}: {
  output?: boolean;
  config: {
    buttonLabel?: string;
    errorRetry?: string;
    errorMessage?: string;
  };
  store: WizardStore;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<any>('');

  React.useEffect(() => {
    const save = async () => {
      setIsLoading(true);
      const formValues = store.formValues.deserialize();
      // FIXME: here remove the "ignores"
      const object = createDataDocument({
        ...formValues,
        type: store.template.docType,
        template_id: store.template._id,
        template_rev: store.template._rev,
      });

      try {
        let dataDoc;
        if (store.db) {
          await store.db.bulkDocs([object]);
          const response = await store.db.allDocs({
            keys: [object._id ? object._id : ''],
            include_docs: true,
            attachments: true,
            binary: true,
            conflicts: true,
          });
          dataDoc = response.rows[0].doc;
          store.formValues.resetValues(getDataFromDataDocument(dataDoc));
        } else {
          setError('NO DB: save not possible');
        }
        setIsLoading(false);
        return dataDoc;
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    store.setSubmit(save);
  }, [setIsLoading, setError, store, config]);

  return output ? null : error ? (
    <Alert
      severity="warning"
      action={
        <Button
          color="inherit"
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setError(false);
          }}
        >
          {config.errorRetry}
        </Button>
      }
    >
      {typeof error === 'string' ? error : config.errorMessage}
    </Alert>
  ) : (
    <Fab
      variant="extended"
      form={store.formValues.id}
      type="submit"
      color="secondary"
      disabled={isLoading || !store.formValues.hasDirtyValues}
    >
      {isLoading ? <Loading /> : config.buttonLabel}
    </Fab>
  );
};

export default observer(Component);

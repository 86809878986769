import React from 'react';
import { Outlet } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';

import AppBar from './AppBar';
import SnackbarMessages from './SnackbarMessages';
import Footer from './Footer';

function Component() {
  return (
    <React.Fragment>
      <AppBar />
      <Toolbar />
      <Outlet />
      <Footer />
      <SnackbarMessages />
    </React.Fragment>
  );
}

export default Component;

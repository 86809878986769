import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CloseIcon from 'components/icons/Close';
import CheckIcon from 'components/icons/Check';
import FieldOutput from 'components/Wizard/Form/FormSectionField/FieldOutput';

const Component = ({
  output,
  value,
  onChange,
  label,
  error,
  helperText,
  //
  variant,
}: any) => {
  const onToggle = () => onChange(!value);
  return output ? (
    <FieldOutput
      label={label}
      error={error}
      helperText={helperText}
      variant={variant}
    >
      {value === 'true' ? (
        <CheckIcon color="success" />
      ) : (
        <CloseIcon color="error" />
      )}
    </FieldOutput>
  ) : (
    <Grid container>
      <Grid item xs={12}>
        {label && (
          <Typography color="textSecondary" variant="caption">
            {label}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {value === 'true' ? (
          <CheckIcon onClick={onToggle} color="success" />
        ) : (
          <CloseIcon onClick={onToggle} color="error" />
        )}
      </Grid>
    </Grid>
  );
};

export default observer(Component);

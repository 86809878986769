// import React from 'react';
import ReactDOM from 'react-dom/client';
import { configure } from 'mobx';
import './index.css';
import App from 'components/App';
import PouchDB from 'pouchdb';
import pouchDbFind from 'pouchdb-find';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

configure({ enforceActions: 'observed' });

PouchDB.plugin(pouchDbFind);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // disabling strict mode for now since it is double rendering and that is annoying...
  // TODO: revisit to improve the unmounting behavior of components that make network requests
  <App />
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

serviceWorkerRegistration.unregister();
// reportWebVitals(console.log);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';

import CircleIcon from 'components/icons/Circle';
import LogoIcon from 'components/icons/Logo';

import Container from 'components/common/Container';
import HideOnScroll from 'components/common/HideOnScroll';

import { useUser } from 'hooks/useUser';

import { getMediaUrlSrc } from 'utils/browser';

function ResponsiveAppBar() {
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoClick = () => {
    navigate('/', { state: { from: location.pathname } });
  };

  const handleLoginClick = () => {
    navigate('/login', { state: { from: location.pathname } });
  };

  const handleJoinClick = () => {
    navigate('/join', { state: { from: location.pathname } });
  };

  const handleDashboardClick = () => {
    handleCloseUserMenu();
    navigate('/dashboard', { state: { from: location.pathname } });
  };

  const handleProfileClick = () => {
    handleCloseUserMenu();
    navigate('/user', { state: { from: location.pathname } });
  };

  const handleLogoutClick = () => {
    handleCloseUserMenu();
    user.removeUser();
    navigate('/');
  };

  return (
    <HideOnScroll>
      <AppBar position="fixed">
        <Container>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 0 }}>
              <Stack
                direction="row"
                sx={{ flexGrow: 0, alignItems: 'center' }}
                spacing={2}
              >
                <LogoIcon onClick={handleLogoClick} />
                <Input placeholder={`Search or jump to...`} />
                {user.isAuthenticated && (
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="subtitle2"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      {'Requests'}
                    </Typography>
                    <CircleIcon radius={12} text={44} color="primary" />
                  </Stack>
                )}
              </Stack>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                minWidth: (theme: any) => theme.spacing(2),
              }}
            ></Box>

            {user.isAuthenticated ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title={'signed in as ' + user.userPerson?.username}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={user.userPerson?.username}
                      src={getMediaUrlSrc(user.userPerson?.photo)}
                    >
                      {user.userPerson?.username?.toUpperCase().slice(0, 2)}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: 5 }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleDashboardClick}>
                    <Typography variant="subtitle2" textAlign="center">
                      {'Dashboard'}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleProfileClick}>
                    <Typography variant="subtitle2" textAlign="center">
                      {'Profile'}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogoutClick}>
                    <Typography variant="subtitle2" textAlign="center">
                      {'Logout'}
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Stack direction="row" sx={{ flexGrow: 0 }} spacing={2}>
                <Button onClick={handleLoginClick}>{'login'}</Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleJoinClick}
                >
                  {'join'}
                </Button>
              </Stack>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
}
export default observer(ResponsiveAppBar);

import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const FieldOutput = ({
  value = null as any,
  label = null as any,
  error = false,
  helperText = null as any,
  password = false,
  children = <div />,
  primary = false,
  labelPrimary = false,
  variant = 'body1' as any,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        {label && (
          <Typography
            variant="caption"
            component="div"
            color={error ? 'error' : labelPrimary ? 'primary' : 'textSecondary'}
          >
            {label}
          </Typography>
        )}
        {(typeof value === 'string' || (error && helperText)) && (
          <Typography
            variant={variant}
            component="div"
            color={error ? 'error' : primary ? 'primary' : 'textPrimary'}
          >
            {password ? [...value].map(() => '*') : error ? helperText : value}
          </Typography>
        )}
        {children}
      </Grid>
    </Grid>
  );
};

export default observer(FieldOutput);

import { makeObservable, observable, action, computed } from 'mobx';

import FormValuesStore from 'state/common/FormValuesStore';
import { Template } from './types';
import processSections from './processSections';

import { getDataFromDataDocument, DataDocument } from 'utils/db';
import { getUUID } from 'utils/tools';

class WizardStore {
  constructor({
    db,
    template,
    data,
    afterSubmit,
  }: {
    db?: PouchDB.Database;
    template: Template;
    data?: DataDocument;
    afterSubmit?: any;
  }) {
    makeObservable(this, {
      isReady: observable,
      isGetting: observable,
      onSubmit: observable,
      sections: computed,
      // onFormSubmit: computed,
      reinitialize: action,
      setSubmit: action,
      init: action,
      initSuccess: action,
      initFailure: action,
    });

    const originalValues = getDataFromDataDocument(data);

    this.db = db;
    this.template = template;
    this.original = originalValues;
    if (afterSubmit) {
      this.afterSubmit = afterSubmit;
    }
  }

  db?: PouchDB.Database = undefined;
  template = {} as Template;
  formValues = {} as FormValuesStore;
  original = {} as DataDocument;

  ignoreFields: string[] = [];
  afterSubmit: any = () => false;

  // OBSERVABLES................................................................
  isReady = false;
  isGetting = false;
  onSubmit: any = () => false;

  // COMPUTEDS..................................................................
  get sections() {
    return this.template.sections;
  }

  // get onFormSubmit() {
  //   return this.onSubmit
  //     ? this.onSubmit
  //     : () => console.error('no onsubmit specified');
  // }

  // ACTIONS....................................................................
  reinitialize = () => {
    this.isReady = false;
  };

  setSubmit = (onSubmit?: any) => {
    this.onSubmit = onSubmit;
  };

  init = async (searchParams: URLSearchParams) => {
    this.isGetting = true;
    let formValues: DataDocument = {
      type: this.template.docType,
      _id: getUUID(),
      ...this.original,
    };
    try {
      if (this.original._id && this.db) {
        const response = await this.db.allDocs({
          keys: [this.original._id],
          include_docs: true,
          attachments: true,
          binary: true,
          conflicts: true,
        });
        formValues = getDataFromDataDocument(response.rows[0].doc);
      }

      this.initSuccess(formValues, searchParams);
    } catch (err) {
      this.initFailure(err);
    }
  };

  initSuccess = (fieldValues: DataDocument, searchParams: URLSearchParams) => {
    const { fieldDefaults, fieldValidators, ignoreFields, urlEnabledFields } =
      processSections(this.template.sections);

    const initialValues = Object.assign({}, fieldDefaults, fieldValues);

    this.formValues = new FormValuesStore({
      initialValues,
      fieldValidators,
    });

    for (let urlField of urlEnabledFields) {
      if (searchParams.has(urlField)) {
        //TODO: fix this since it will affect the validation (need to do the per section)
        this.formValues.setValue(urlField, searchParams.get(urlField));
      }
    }

    this.ignoreFields = ignoreFields;

    this.isGetting = false;
    this.isReady = true;
  };

  initFailure = (err: any) => {
    console.log(err);
    this.isGetting = false;
  };
}

export default WizardStore;

import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

import Container from 'components/common/Container';
import Wizard from 'components/Wizard';

import userRegistrationTemplate from './userRegistrationTemplate';
import { useUser } from 'hooks/useUser';

function Component() {
  const user = useUser();
  return user.isAuthenticated ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <Container pageConatiner>
      <Wizard template={userRegistrationTemplate} />
    </Container>
  );
}

export default observer(Component);

import React from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import Loading from 'components/common/Loading';

import WizardStore from 'state/common/WizardStore';

// import { ActionField } from 'state/common/WizardStore/types' // <-- needs to be actionfieldcomponent not the field since this is used in the template

const Component = ({
  output,
  config,
  store,
}: {
  output?: boolean;
  config: {
    emailField: string;
    successField: string;
    errorMessage: string;
    errorRetry: string;
    buttonLabel: string;
    successMessage: string;
  };
  store: WizardStore;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFinished, setIsFinished] = React.useState(false);
  const [error, setError] = React.useState<any>('');
  const apiUrl = 'https://api.realtimeaid.live/users/emailVerification';

  React.useEffect(() => {
    const getVerification = async () => {
      setIsLoading(true);
      try {
        await axios.post(apiUrl, {
          email: store.formValues.values.get(config.emailField),
        });
        store.formValues.setValue(config.successField, 'true');
        setIsLoading(false);
        setIsFinished(true);
      } catch (err: any) {
        setError(err || true);
        setIsLoading(false);
      }
    };
    store.setSubmit(getVerification);
  }, [setIsLoading, setIsFinished, setError, store, config]);

  return output ? null : isFinished ? (
    <Alert severity="info">{config.successMessage}</Alert>
  ) : error ? (
    <Alert
      severity="warning"
      action={
        <Button
          color="inherit"
          disabled={isLoading || isFinished}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setError(false);
          }}
        >
          {config.errorRetry}
        </Button>
      }
    >
      {typeof error === 'string' ? error : config.errorMessage}
    </Alert>
  ) : (
    <Button
      form={store.formValues.id}
      fullWidth
      type="submit"
      variant="contained"
      color="secondary"
      disabled={isLoading || isFinished}
    >
      {isLoading ? <Loading /> : config.buttonLabel}
    </Button>
  );
};

export default observer(Component);

import { observer } from 'mobx-react-lite';
import { Navigate, useLocation } from 'react-router-dom';

import Container from 'components/common/Container';
import Wizard from 'components/Wizard';

import { useUser } from 'hooks/useUser';

import userLoginTemplate from './userLoginTemplate';

function Component() {
  const user = useUser();
  const location = useLocation();
  return user.isAuthenticated ? (
    <Navigate to={location.state?.from || '/dashboard'} replace={true} />
  ) : (
    <Container pageConatiner>
      <Wizard template={userLoginTemplate} />
    </Container>
  );
}

export default observer(Component);

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { useTheme } from 'hooks/useTheme';

const Circle = ({
  text,
  radius,
  color,
  textColor,
  icon,
  fontSize,
  ...rest
}: any) => {
  const { activeTheme: theme } = useTheme();
  return (
    <SvgIcon
      sx={{ color: textColor || 'inherit' }}
      fontSize={fontSize}
      {...rest}
    >
      <circle
        style={{
          color: color === 'primary' ? theme.palette.primary.main : color,
          fill: color === 'primary' ? theme.palette.primary.main : color,
        }}
        cx={radius}
        cy={radius}
        r={radius}
      />
      {text ? (
        <text
          style={{
            fill: textColor || theme.palette.text.primary,
            fontSize: theme.typography.caption.fontSize,
            fontFamily: theme.typography.fontFamily,
          }}
          x="12"
          y="16"
          textAnchor="middle"
        >
          {text}
        </text>
      ) : (
        icon
      )}
    </SvgIcon>
  );
};

export default Circle;

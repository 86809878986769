import { isRtlLang } from 'rtl-detect';
import { Direction } from '@mui/material/styles';

export const scrollToTop = () => window.scrollTo(0, 0);

export const getScreenSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const configureTextDirection = (lang?: string): Direction => {
  let language = lang || getLanguagePreference();
  let direction: Direction = isRtlLang(language) ? 'rtl' : 'ltr';
  // make sure dir is set on document that matches actual preferred direction
  setDocumentDir(direction);
  return direction;
};

export const navigateTo = (url: string) => {
  window.open(url);
};

export const getLanguagePreference = () => {
  // https://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference
  const browserLanguagePropertyKeys = [
    'language',
    'browserLanguage',
    'systemLanguage',
    'userLanguage',
  ];
  let language = 'en'; // <-- default to english

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(window.navigator.languages)) {
    for (let i = 0; i < window.navigator.languages.length; i++) {
      language = window.navigator.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = window.navigator[
      browserLanguagePropertyKeys[i] as keyof Navigator
    ] as string;
    if (language && language.length) {
      return language;
    }
  }

  return language;
};

const globMediaUrlMap = new WeakMap(); // <-- protection for memory leak with 'createObjectURL'
export const getMediaUrlSrc = (media: any) => {
  if (media && typeof media === 'string') {
    return media;
  } else if (media instanceof Blob || media instanceof File) {
    if (globMediaUrlMap.has(media)) {
      return globMediaUrlMap.get(media);
    } else {
      let url = URL.createObjectURL(media);
      globMediaUrlMap.set(media, url);
      return url;
    }
  } else {
    return '';
  }
};

export const setDocumentDir = (dir: string) => {
  if (document) {
    document.dir = dir;
    document.body.dir = dir;
  }
};

declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
    mozSpeechRecognition: any;
    msSpeechRecognition: any;
    oSpeechRecognition: any;
  }
}

export const speechRecognitionSupported =
  typeof window !== 'undefined' &&
  !!(
    window.SpeechRecognition ||
    window.webkitSpeechRecognition ||
    window.mozSpeechRecognition ||
    window.msSpeechRecognition ||
    window.oSpeechRecognition
  );

export const getBrowserSpeechRecognition = () => {
  if (speechRecognitionSupported) {
    return (
      window.SpeechRecognition ||
      window.webkitSpeechRecognition ||
      window.mozSpeechRecognition ||
      window.msSpeechRecognition ||
      window.oSpeechRecognition
    );
  } else {
    return null;
  }
};

export const getSearchParams = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  });
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  return params;
};

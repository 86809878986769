import { Template } from 'state/common/WizardStore/types';

const template: Template = {
  language: 'en',
  docType: 'person',
  publishAllowed: true,
  submitField: {
    xs: 'auto',
    actionComponent: 'SaveDataDocument',
    config: {
      buttonLabel: 'Save profile',
      errorMessage: 'Something went wrong.',
      errorRetry: 'Try again',
    },
  },
  sections: [
    {
      maxWidth: 'md',
      fields: [
        {
          component: 'BannerImage',
          label: 'Banner picture',
          name: 'banner',
        },
        [
          {
            component: 'PhotoAvatar',
            name: 'photo',
            label: 'Avatar picture',
            sm: 4,
          },
          [
            {
              label: 'Username',
              output: true,
              component: 'Text',
              name: 'username',
              hidden: false,
              disabled: true,
              isRequired: 'Username is required.',
              isUnchangeable: 'Username cannot be changed.',
            },
            {
              label: 'Name',
              component: 'Text',
              name: 'name',
              isRequired: 'Name is required.',
              isString255: 'Name cannot be longer than 255 characters.',
            },
            {
              label: 'Description',
              component: 'Text',
              rows: 12,
              name: 'description',
              isRequired: 'Description is required',
            },
            {
              label: 'Location',
              component: 'Text',
              name: 'location',
            },
          ],
        ],
      ],
    },
  ],
};

export default template;

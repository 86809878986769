import { hexToRGBA } from 'utils/tools';

const colors = {
  white: '#ffffff',
  black: '#000000',

  medDarkWhite: '#f0f0f0',
  darkWhite: '#e5e5e5',
  darkDarkWhite: '#DFDFDF',
  darkDarkDarkWhite: '#c4c4c9',
  lightLightLicorice: '#A6A2AA',
  lightLicorice: '#667282',
  licorice: '#3c4858',
  medDarkLicorice: '#28303e',
  darkLicorice: '#212731',
  darkDarkLicorice: '#171b22',

  lightTeal: '#33bccd',
  teal: '#00acc1',
  darkTeal: '#007887',

  lightRedOrange: '#ff4569',
  redOrange: '#ff1744',
  darkRedOrange: '#b2102f',

  lightGreen: '#55c48f',
  green: '#2bb673',
  darkGreen: '#1e7f50',

  lightYellow: '#ffd95a',
  yellow: '#f9a825',
  darkYellow: '#c17900',
};

// would be better to base colors off of these:
// #ff7d7a
// #80d8ff

export const paletteLight = {
  mode: 'light' as any,
  background: {
    default: colors.medDarkWhite,
    paper: colors.white,
  },
  grey: {
    50: colors.medDarkWhite,
    100: colors.darkWhite,
    200: colors.darkDarkWhite,
    300: colors.darkDarkDarkWhite,
    400: colors.lightLightLicorice,
    500: colors.lightLicorice,
    600: colors.licorice,
    700: colors.medDarkLicorice,
    800: colors.darkLicorice,
    900: colors.darkDarkLicorice,
  },
  primary: {
    light: colors.lightTeal,
    main: colors.teal,
    dark: colors.darkTeal,
    contrastText: colors.white,
  },
  secondary: {
    light: colors.lightGreen,
    main: colors.green,
    dark: colors.darkGreen,
    contrastText: colors.white,
  },
  error: {
    light: colors.lightRedOrange,
    main: colors.redOrange,
    dark: colors.darkRedOrange,
    contrastText: colors.white,
  },
  warning: {
    light: colors.lightYellow,
    main: colors.yellow,
    dark: colors.darkYellow,
  },
  success: {
    light: colors.lightGreen,
    main: colors.green,
    dark: colors.darkGreen,
    contrastText: colors.white,
  },
};

export const paletteDark = Object.assign({}, paletteLight, {
  mode: 'dark' as any,
  background: {
    default: colors.darkDarkLicorice,
    paper: colors.darkLicorice,
  },
});

export const componentsDark = {
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: hexToRGBA(colors.licorice, '0.2'),
        backgroundImage: 'none',
        backdropFilter: 'blur(20px);',
        boxShadow: 'none',
      },
    },
  },
};

export const componentsLight = {
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: hexToRGBA(colors.white, '0.8'),
        backgroundImage: 'none',
        backdropFilter: 'blur(20px);',
        boxShadow: 'none',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: hexToRGBA(colors.white, '1'),
        backgroundImage: 'none',
        backdropFilter: 'blur(20px);',
        boxShadow: 'none',
      },
    },
  },
};

import { observer } from 'mobx-react-lite';
import { Navigate, useLocation } from 'react-router-dom';

import Container from 'components/common/Container';

import { useUser } from 'hooks/useUser';

function Component() {
  const user = useUser();
  const location = useLocation();

  return !user.isAuthenticated ? (
    <Navigate
      to="/login"
      state={{ from: location.pathname + location.search }}
      replace
    />
  ) : (
    <Container pageConatiner>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
      <div>dashboard</div>
    </Container>
  );
}

export default observer(Component);

import { Template } from 'state/common/WizardStore/types';

const template: Template = {
  language: 'en',
  sections: [
    {
      title: 'Login',
      label: 'Login',
      maxWidth: 'xs',
      fields: [
        {
          label: 'Username',
          component: 'Text',
          name: 'username',
          defaultValue: 'steven1234',
          isRequired: 'Username is required.',
        },
        {
          label: 'Password',
          component: 'Text',
          name: 'password',
          defaultValue: 'testtest12341234',
          isRequired: 'Email is required.',
          isPassword: 'Password must match criteria.',
        },
        {
          actionComponent: 'AuthenticateRemoteDb',
          config: {
            userDocType: 'person',
            buttonLabel: 'Login',
            errorMessage: 'Something went wrong.',
            errorRetry: 'Try again',
            errorNotFoundMessage: 'Username or password incorrect.',
          },
        },
      ],
    },
  ],
};

export default template;

import {
  Section,
  DataField,
  SectionField,
} from 'state/common/WizardStore/types';
import {
  FieldValidator,
  combineFieldValidators,
  required,
  string255,
  integer,
  email,
  url,
  unchangeable,
} from 'state/common/FormValuesStore/fieldValidators';

const processSections = (sections: Section[]) => {
  let fieldDefaults = {} as { [key: string]: any };
  let fieldValidators = {} as { [key: string]: FieldValidator };
  let ignoreFields: string[] = [];
  let urlEnabledFields: string[] = [];

  const setDataField = (f: DataField) => {
    if ('defaultValue' in f) {
      fieldDefaults[f.name] = f.defaultValue;
    }
    if (f.ignore) {
      ignoreFields.push(f.name);
    }
    if (f.urlEnabled) {
      urlEnabledFields.push(f.name);
    }
    fieldValidators[f.name] = combineFieldValidators([
      ...(f.isRequired ? [required(f.isRequired)] : []),
      ...(f.isString255 ? [string255(f.isString255)] : []),
      ...(f.isInteger ? [integer(f.isInteger)] : []),
      ...(f.isEmail ? [email(f.isEmail)] : []),
      ...(f.isUrl ? [url(f.isUrl)] : []),
      ...(f.isUnchangeable ? [unchangeable(f.isUnchangeable)] : []),
    ]);
  };

  const resolveField = (field: SectionField) => {
    if (Array.isArray(field)) {
      field.forEach((f) => {
        resolveField(f);
      });
    } else {
      if ('actionComponent' in field) {
        // do nothing
      } else {
        setDataField(field);
      }
    }
  };

  for (let section of sections) {
    for (let field of section.fields) {
      resolveField(field);
    }
  }

  return {
    fieldDefaults,
    fieldValidators,
    ignoreFields,
    urlEnabledFields,
  };
};

export default processSections;

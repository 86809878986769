import { makeObservable, observable, action, computed } from 'mobx';
import { getUUID } from 'utils/tools';

export type Message = {
  action?: Function;
  actionMessage?: string;
  key?: string;
  message?: string;
  time?: number;
  visible?: boolean;
  syncPull?: boolean;
  syncPush?: boolean;
};

class SnackbarMessageStore {
  constructor() {
    makeObservable(this, {
      messages: observable.shallow,
      activeMessage: computed,
      addMessage: action,
      addMultipleMessages: action,
      removeMessage: action,
      runActiveMessageAction: action,
      reset: action,
    });
  }
  // OBSERVABLES................................................................
  messages = observable([] as Message[]);

  // COMPUTEDS..................................................................
  get activeMessage() {
    return this.messages.filter((m: any) => m.visible)[0] || {};
  }

  // ACTIONS....................................................................
  reset = () => {
    this.messages.clear();
  };
  addMessage = (message: Message = {}) => {
    if (message && Object.keys(message).length > 0) {
      const newMessage: Message = Object.assign(message, {
        key: getUUID(),
        time: new Date().getTime(),
        visible: true,
      });

      this.messages.push(newMessage);
    } else {
      console.warn('[SnackbarMessage] no message content');
    }
  };

  addMultipleMessages = (messages = []) => {
    for (let message of messages) {
      this.addMessage(message);
    }
  };

  removeMessage = (message = {}) => {
    this.messages.remove(message);
  };

  removeActiveMessage = () => {
    this.removeMessage(this.activeMessage);
  };

  runActiveMessageAction = () => {
    if (typeof this.activeMessage.action === 'function') {
      this.activeMessage.action();
      this.removeActiveMessage();
    } else {
      console.warn('[SnackbarMessage] no message action');
    }
  };
}

export default SnackbarMessageStore;

// import React from 'react'
import { observer } from 'mobx-react-lite';
// import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid';

import { Field } from 'state/common/WizardStore/types';
import * as FieldComponents from './fieldComponents';
import * as ActionComponents from './fieldActionComponents';

import WizardStore from 'state/common/WizardStore';

function Component({ field, store }: { field: Field; store: WizardStore }) {
  // const navigate = useNavigate()
  let renderComponent;
  let conditionsMet = true;

  if ('if' in field) {
    conditionsMet =
      field.if?.condition === 'EQUALS'
        ? store.formValues.values.get(field.if.target) === field.if.value
        : true;
  }

  if ('actionComponent' in field) {
    // @ts-ignore
    const ActionComponent = ActionComponents[field.actionComponent];
    if (!ActionComponent) {
      console.log(
        `No matching field action component for ${field.actionComponent}`
      );
      renderComponent = (
        <div>{`No matching field action component for ${field.actionComponent}`}</div>
      );
    } else {
      renderComponent = <ActionComponent {...field} store={store} />;
    }
  } else {
    // @ts-ignore
    const FieldComponent = FieldComponents[field.component];
    if (!FieldComponent) {
      console.log(`No matching field component for ${field.component}`);
      renderComponent = (
        <div>{`No matching field component for ${field.component}`}</div>
      );
    } else {
      renderComponent = (
        <FieldComponent
          {...field}
          form={store.formValues.id}
          value={store.formValues.values.get(field.name)}
          onChange={(value: any) =>
            store.formValues.setValue(field.name, value)
          }
          onValidate={() => store.formValues.checkFieldError(field.name)}
          error={store.formValues.errors.has(field.name)}
          helperText={
            store.formValues.errors.has(field.name) &&
            store.formValues.errors.get(field.name)
          }
        />
      );
    }
  }

  return conditionsMet && !field.hidden ? (
    <Grid
      item
      xs={field.xs || 12}
      sm={field.sm || field.xs || 12}
      md={field.md || field.sm || field.xs || 12}
    >
      {renderComponent}
    </Grid>
  ) : null;
}

export default observer(Component);

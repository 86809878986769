import React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Transition } from 'react-transition-group';

import { useTheme } from 'hooks/useTheme';

const HideOnScroll = ({ offset = 0, direction = 'up', children }: any) => {
  const trigger = useScrollTrigger({ target: window });
  const { activeTheme: theme } = useTheme();
  const nodeRef = React.useRef(null) as any;

  return (
    <Transition
      nodeRef={nodeRef}
      appear={false}
      in={!trigger}
      timeout={0}
      onExited={() => {
        const node = nodeRef.current.firstChild;
        const { top, height } = node.getBoundingClientRect();
        const transform =
          direction === 'up'
            ? `translateY(-${top + height - offset}px)`
            : `translateY(${height - offset}px)`;
        node.style.transform = transform;
        node.style.webkitTransform = transform;

        const options = {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.easeOut,
        };
        node.style.transition = theme.transitions.create(
          ['transform'],
          options
        );
        node.style.webkitTransition = theme.transitions.create(
          ['-webkit-transform'],
          options
        );
      }}
      onEntered={() => {
        const node = nodeRef.current.firstChild;
        node.style.transform = 'none';
        node.style.webkitTransform = 'none';

        const options = {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeIn,
        };
        node.style.transition = theme.transitions.create(
          ['transform'],
          options
        );
        node.style.webkitTransition = theme.transitions.create(
          ['-webkit-transform'],
          options
        );
      }}
    >
      <div ref={nodeRef}>{children}</div>
    </Transition>
  );
};

export default HideOnScroll;

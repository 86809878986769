import React from 'react';
import PouchDB from 'pouchdb';
import { observer } from 'mobx-react-lite';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

import Loading from 'components/common/Loading';

import { createDataDocument } from 'utils/db';
import WizardStore from 'state/common/WizardStore';

const dbUrl = 'https://dev.realtimeaid.live:6984';
const db = new PouchDB(`${dbUrl}/public`);

// import { ActionField } from 'state/common/WizardStore/types' // <-- needs to be actionfieldcomponent not the field since this is used in the template

const Component = ({
  output,
  config,
  store,
}: {
  output?: boolean;
  config: {
    buttonLabel?: string;
    errorRetry?: string;
    errorMessage?: string;
  };
  store: WizardStore;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<any>('');
  const navigate = useNavigate();

  const publish = async () => {
    setIsLoading(true);
    try {
      const formValues = store.formValues.deserialize();
      if (!formValues._id) return;
      const response = await db.allDocs({
        keys: [formValues._id],
      });
      const rev = response.rows[0]?.value?.rev || undefined;

      // FIXME: here remove the "ignores"
      const object = createDataDocument({
        ...formValues,
        _rev: rev,
        type: store.template.docType,
        template_id: store.template._id,
        template_rev: store.template._rev,
      });

      await db.bulkDocs([object]);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setError(err);
      setIsLoading(false);
    }
  };

  return output ? null : error ? (
    <Alert
      severity="warning"
      action={
        <Button
          color="inherit"
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setError(false);
          }}
        >
          {config.errorRetry}
        </Button>
      }
    >
      {typeof error === 'string' ? error : config.errorMessage}
    </Alert>
  ) : (
    <Stack spacing={1} direction="row">
      <Button
        onClick={() =>
          navigate(`/user/${store.formValues.values.get('username')}`)
        }
      >
        view
      </Button>
      <Fab
        variant="extended"
        form={store.formValues.id}
        color="secondary"
        onClick={publish}
        disabled={isLoading}
      >
        {isLoading ? <Loading /> : config.buttonLabel}
      </Fab>
    </Stack>
  );
};

export default observer(Component);

import React from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';

import FormSectionField from '../FormSectionField';
import { SectionField } from 'state/common/WizardStore/types';

import WizardStore from 'state/common/WizardStore';

function Component({
  fields,
  store,
}: {
  fields: SectionField[];
  store: WizardStore;
}) {
  return (
    <Grid container spacing={{ xs: 2, sm: 3 }}>
      {fields.map((f: SectionField, i) => {
        const group = Array.isArray(f);
        return group ? (
          <Grid item xs key={i}>
            <Component fields={f} store={store} />
          </Grid>
        ) : (
          <FormSectionField key={i} field={f} store={store} />
        );
      })}
    </Grid>
  );
}

export default observer(Component);

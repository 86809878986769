import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PouchDB from 'pouchdb';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';

import Container from 'components/common/Container';
import Wizard from 'components/Wizard';
import Loading from 'components/common/Loading';

import { useUser } from 'hooks/useUser';

import publicTemplate from './userPublicProfileTemplate';
import template from './userProfileTemplate';
import { DataDocument } from 'utils/db';

const dbUrl = 'https://dev.realtimeaid.live:6984';
const db = new PouchDB(`${dbUrl}/public`);

function Component() {
  const user = useUser();
  const { username } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (username && !data && !loading) {
      const load = async () => {
        setLoading(true);
        try {
          const userRes = await db.find({
            fields: ['_id'],
            selector: {
              type: {
                $eq: 'person',
              },
              username: {
                $eq: username,
              },
            },
          });
          const userId = userRes.docs[0]?._id;
          const response = await db.allDocs({
            keys: [userId],
            include_docs: true,
            attachments: true,
            binary: true,
            conflicts: true,
          });
          setData(response.rows[0].doc || {});
          setLoading(false);
        } catch (err) {
          setError(true);
          setLoading(false);
          console.log(err);
        }
      };
      load();
    }
  }, [setLoading, setData, setError, data, loading, username]);

  const location = useLocation();
  return !username && !user.isAuthenticated ? (
    <Navigate
      to="/login"
      state={{ from: location.pathname + location.search }}
      replace
    />
  ) : (
    <Container pageConatiner>
      {loading ? (
        <Loading />
      ) : error ? (
        <Alert severity="error">?</Alert>
      ) : username ? (
        <Wizard template={publicTemplate} data={data} />
      ) : (
        <Wizard
          db={user.userRemoteDb}
          data={{ _id: user.userId }}
          template={template}
          afterSubmit={(data: DataDocument) => {
            user.setUser({
              userPerson: data,
            });
          }}
        />
      )}
    </Container>
  );
}

export default observer(Component);

import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

function Icon(props: any) {
  return (
    <SvgIcon viewBox="0 0 4200 4200" {...props}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="4200px"
        height="4200px"
        enableBackground="new 0 0 192 192"
      >
        <g
          transform="translate(0,4200) scale(0.100000,-0.100000)"
          fill="inherit"
          stroke="none"
        >
          <path d="M16155 38984 c-164 -25 -322 -82 -470 -169 -322 -191 -531 -478 -628 -865 l-22 -85 -5 -5392 -5 -5392 -21 -27 c-11 -15 -33 -37 -48 -48 l-27 -21 -5377 -5 -5377 -6 -95 -21 c-286 -63 -494 -176 -690 -372 -183 -184 -293 -380 -358 -641 l-27 -105 -2 -4798 c-3 -4161 -1 -4811 11 -4895 89 -587 540 -1038 1126 -1127 82 -13 723 -15 4576 -15 4166 0 4484 1 4530 17 67 23 145 102 168 170 15 45 17 91 14 365 -3 301 -4 315 -25 354 -28 53 -74 99 -127 127 l-41 22 -4485 5 -4485 5 -45 24 c-61 32 -97 67 -128 126 l-27 50 0 4720 0 4720 21 46 c26 55 77 108 130 135 38 19 123 19 5414 24 l5375 5 93 22 c194 45 311 94 457 191 128 85 245 202 333 336 102 154 167 324 191 500 8 54 11 1668 11 5405 0 4830 1 5332 16 5381 22 74 101 153 175 174 49 15 508 16 4754 14 l4700 -3 50 -27 c59 -31 94 -67 126 -128 l24 -45 5 -4485 5 -4485 22 -41 c29 -55 103 -119 160 -138 68 -23 605 -23 683 0 74 22 152 100 174 174 15 49 16 476 16 4529 0 3849 -2 4489 -15 4571 -88 585 -540 1037 -1125 1125 -139 22 -9567 20 -9705 -1z" />
          <path d="M27405 26993 c-2867 -98 -5581 -1143 -7810 -3007 -509 -426 -1155 -1072 -1581 -1581 -1720 -2057 -2749 -4539 -2968 -7160 -42 -502 -41 -332 -41 -6055 0 -5324 1 -5487 19 -5555 80 -301 310 -531 611 -611 68 -18 227 -19 5365 -19 5138 0 5297 1 5365 19 299 79 524 303 611 606 18 63 19 222 24 5050 l5 4985 23 100 c34 147 77 265 144 398 209 411 589 700 1063 809 l100 23 4985 5 c4828 5 4987 6 5050 24 303 87 527 312 606 611 18 68 19 227 19 5365 0 5138 -1 5297 -19 5365 -79 299 -303 524 -606 611 -63 18 -220 19 -5475 20 -2975 0 -5446 -1 -5490 -3z" />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default Icon;

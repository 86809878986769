import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';

import MicIcon from '@mui/icons-material/Mic';
import TextField from '@mui/material/TextField';
import CircleIcon from 'components/icons/Circle';
import FieldOutput from 'components/Wizard/Form/FormSectionField/FieldOutput';

import { useSpeechRecognition } from 'hooks/useSpeechRecognition';
import { useTheme } from 'hooks/useTheme';
import { getLanguagePreference } from 'utils/browser';

const Component = ({
  output,
  value,
  onChange,
  onValidate,
  name,
  label,
  error,
  helperText,
  form,
  //
  rows,
  isRequired,
  isEmail,
  isPassword,
  disabled,
  autoFocus,
  speechRecognitionEnabled = false,
  primary,
  labelPrimary,
  variant,
}: any) => {
  const { activeTheme: theme } = useTheme();
  const { init, startRecognition, guess, stopRecognition, ready } =
    useSpeechRecognition();
  const [recording, setRecording] = useState(false);
  const inputRef = React.useRef() as any;

  const onEnd = (finalOutput: string) => {
    setRecording(false);
    onChange(finalOutput);
  };

  const onGuess = (guess: string) => {
    onChange(guess);
    inputRef.current.scrollTop = inputRef.current.scrollHeight;
  };

  const onMicClick = () => {
    init(getLanguagePreference());
    setRecording(true);
    inputRef.current.focus();
    startRecognition({ onEnd, output: value, onGuess });
  };

  const onRecordCancel = () => {
    if (recording) {
      setRecording(false);
      stopRecognition();
    }
  };

  const onBlur = () => {
    if (onValidate) {
      onValidate();
    }
    if (recording) {
      setRecording(false);
      onChange(guess);
      stopRecognition();
    }
  };

  return output ? (
    <FieldOutput
      value={value}
      label={label}
      error={error}
      helperText={helperText}
      password={name.includes('password')}
      primary={primary}
      labelPrimary={labelPrimary}
      variant={variant}
    />
  ) : (
    <div style={{ position: 'relative' }}>
      {speechRecognitionEnabled && (
        <div
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: theme.spacing(0.5),
            right: theme.direction === 'ltr' ? theme.spacing(0.5) : 'unset',
            left: theme.direction === 'rtl' ? theme.spacing(0.5) : 'unset',
            zIndex: 1000,
          }}
        >
          {recording ? (
            <IconButton
              size="small"
              aria-label="stop recording"
              onClick={onRecordCancel}
            >
              <CircleIcon radius={12} color={theme.palette.error.main} />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              aria-label="voice to text"
              disabled={!ready}
              onClick={onMicClick}
            >
              <MicIcon />
            </IconButton>
          )}
        </div>
      )}
      <TextField
        inputProps={{ form }}
        inputRef={inputRef}
        type={isPassword ? 'password' : isEmail ? 'email' : undefined}
        fullWidth
        multiline={!!rows && rows > 0}
        rows={rows}
        autoFocus={autoFocus}
        label={label}
        name={name}
        value={value || ''}
        onChange={(e: any) => {
          if (recording) {
            onRecordCancel();
          }
          onChange(e.target.value);
        }}
        required={isRequired === '' || !!isRequired}
        error={error}
        helperText={helperText}
        disabled={disabled}
        onBlur={onBlur}
      />
    </div>
  );
};

export default observer(Component);

import { makeObservable, observable, action, computed } from 'mobx';

import { getDataFromDataDocument } from 'utils/db';
import { DataDocument } from 'utils/db';

class UserStore {
  constructor() {
    makeObservable(this as UserStore, {
      userId: observable,
      userPerson: observable,
      isAuthenticated: computed,
      setUser: action,
      removeUser: action,
    });
  }

  userRemoteDb?: PouchDB.Database = undefined;

  // OBSERVABLES................................................................
  userId?: string;
  userPerson?: DataDocument;

  // COMPUTEDS..................................................................
  get isAuthenticated() {
    return !!this.userId;
  }

  // ACTIONS....................................................................
  setUser = async ({
    userId,
    userPerson,
    userRemoteDb,
  }: {
    userId?: string;
    userPerson?: DataDocument;
    userRemoteDb?: PouchDB.Database;
  }) => {
    if (userId) {
      this.userId = userId;
    }
    if (userPerson) {
      this.userPerson = getDataFromDataDocument(userPerson);
    }
    if (userRemoteDb) {
      this.userRemoteDb = userRemoteDb;
    }
  };

  removeUser = () => {
    this.userId = undefined;
    this.userPerson = undefined;
    this.userRemoteDb = undefined;
  };
}

export default UserStore;

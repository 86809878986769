import { nanoid } from 'nanoid';

export const getUUID = () => {
  return nanoid();
};

export const hexToRGBA = (hex: any, alpha = '1') => {
  const newHex = hex.startsWith('#') ? hex.slice(1) : hex;

  if (newHex.length !== 6) {
    throw new Error('invalid hex color');
  }

  const [red, green, blue] = newHex.match(/.{1,2}/g).map((hex: any) => {
    const colorNumber = parseInt(hex, 16);

    if (isNaN(colorNumber)) {
      throw new Error('invalid hex number');
    }

    return colorNumber;
  });

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export const hexEncodeString = (string = '') => {
  let result = '';
  for (let i = 0; i < string.length; i++) {
    result += string.charCodeAt(i).toString(16);
  }

  return result;
};

export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target: any, ...sources: any): any {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (
        isObject(source[key]) &&
        !(source[key] instanceof Blob || source[key] instanceof File)
      ) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

import React from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';

import FormSection from '../FormSection';
import FormSectionField from '../FormSectionField';

import WizardStore from 'state/common/WizardStore';

function Component({ store }: { store: WizardStore }) {
  return (
    <Grid container spacing={{ xs: 2, sm: 3 }} justifyContent={'center'}>
      {store.sections.map((section, i) => (
        <React.Fragment key={i}>
          <FormSection section={section} store={store} />
        </React.Fragment>
      ))}
      {
        //TODO: make sure this is called only on the last section when paging the sections
      }
      {store.template.publishAllowed && (
        <FormSectionField
          store={store}
          field={{
            xs: 'auto',
            actionComponent: 'PublishDataDocument',
            config: {
              buttonLabel: 'Publish',
              errorMessage: 'Something went wrong.',
              errorRetry: 'Try again',
            },
          }}
        />
      )}

      {store.template.submitField && (
        <FormSectionField store={store} field={store.template.submitField} />
      )}
    </Grid>
  );
}

export default observer(Component);

import { makeObservable, observable, action, computed, toJS } from 'mobx';

import { FieldValidator } from './fieldValidators';
import { getUUID } from 'utils/tools';

class FormValuesStore {
  constructor({
    initialValues = {},
    fieldValidators = {},
  }: {
    initialValues?: { [key: string]: any };
    fieldValidators?: { [key: string]: FieldValidator };
  }) {
    makeObservable(this as FormValuesStore, {
      values: observable,
      dirtyValues: observable,
      errors: observable,
      hasErrors: computed,
      hasDirtyValues: computed,
      setValue: action,
      clear: action,
      clearErrors: action,
      clearDirtyValues: action,
      resetValues: action,
      checkFieldErrors: action,
      checkFieldError: action,
      setFieldError: action,
      removeFieldError: action,
      deserialize: action,
      deserializeDirty: action,
    });
    this.id = getUUID();
    this.initialValues = Object.assign({}, initialValues);
    this.fieldValidators = Object.assign({}, fieldValidators);

    this.values.replace(this.initialValues);
  }

  id;
  initialValues;
  fieldValidators;

  // OBSERVABLES................................................................
  values = observable.map({});
  dirtyValues = observable.map({});
  errors = observable.map({});

  // COMPUTEDS..................................................................
  get hasErrors() {
    return this.errors.size > 0;
  }

  get errorFields() {
    return Object.keys(this.errors.toJSON());
  }

  get hasDirtyValues() {
    return this.dirtyValues.size > 0;
  }

  // ACTIONS....................................................................
  setValue = (key = '', value: any): void => {
    this.values.set(key, value);
    this.dirtyValues.set(key, value);
    if (this.errors.has(key)) {
      this.checkFieldError(key);
    }
  };

  clear = (): void => {
    this.values.clear();
    this.dirtyValues.clear();
    this.errors.clear();
  };

  clearErrors = (): void => {
    this.errors.clear();
  };

  clearDirtyValues = (): void => {
    this.dirtyValues.clear();
  };

  resetValues = (values?: { [key: string]: any }): void => {
    this.values.replace(
      Object.assign({}, values ? values : this.initialValues)
    );
    this.dirtyValues.clear();
    this.errors.clear();
  };

  checkFieldErrors = (): void => {
    const validatorFieldNames = Object.keys(this.fieldValidators);
    for (let validatorFieldName of validatorFieldNames) {
      this.checkFieldError(validatorFieldName);
    }
  };

  checkFieldError = (field: string): void => {
    const validateField = this.fieldValidators[field];
    const error = validateField
      ? validateField(
          this.values.get(field),
          field,
          toJS(this.deserialize()),
          toJS(this.initialValues)
        )
      : false;
    if (error) {
      this.errors.set(field, error);
    } else {
      this.errors.delete(field);
    }
  };

  checkMultipleFieldErrors = (fields = []): void => {
    for (let field of fields) {
      this.checkFieldError(field);
    }
  };

  setFieldError = (field?: string, error?: any): void => {
    if (field) {
      this.errors.set(field, error);
    }
  };

  removeFieldError = (field?: string): void => {
    this.errors.delete(field);
  };

  deserialize = (): { [key: string]: any } => {
    return toJS(Object.fromEntries(this.values));
  };

  deserializeDirty = (): { [key: string]: any } => {
    return toJS(Object.fromEntries(this.dirtyValues));
  };
}

export default FormValuesStore;

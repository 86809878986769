// import React from 'react'
import { observer } from 'mobx-react-lite';

import FormSections from './FormSections';

import WizardStore from 'state/common/WizardStore';

function Component({ store }: { store: WizardStore }) {
  const asyncSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const data = await store.onSubmit();
      store.afterSubmit(data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <form id={store.formValues.id} onSubmit={asyncSubmit} />
      <FormSections store={store} />
    </>
  );
}

export default observer(Component);

import { isValidEmail, isValidUrl, isValidNumber } from 'utils/validators';

export type FieldValidator = (
  value: any,
  key?: string,
  allFormValues?: { [key: string]: any },
  initialValues?: { [key: string]: any }
) => string;

export const required =
  (errorMessage: string): FieldValidator =>
  (value: any) => {
    return value === null || value === undefined || value === ''
      ? errorMessage === ''
        ? 'This is required.'
        : errorMessage
      : '';
  };

export const string255 =
  (errorMessage: string): FieldValidator =>
  (value: any) => {
    return typeof value === 'string' && value.length > 255
      ? errorMessage === ''
        ? 'This must be shorter than 255 characters.'
        : errorMessage
      : '';
  };

export const integer =
  (errorMessage: string): FieldValidator =>
  (value: any) => {
    return !isValidNumber(value)
      ? errorMessage === ''
        ? 'This must be an integer.'
        : errorMessage
      : '';
  };

export const email =
  (errorMessage: string): FieldValidator =>
  (value: any) => {
    return !isValidEmail(value)
      ? errorMessage === ''
        ? 'This must be a valid email.'
        : errorMessage
      : '';
  };

export const url =
  (errorMessage: string): FieldValidator =>
  (value: any) => {
    return !isValidUrl(value)
      ? errorMessage === ''
        ? 'This must be a valid URL.'
        : errorMessage
      : '';
  };

export const unchangeable =
  (errorMessage: string): FieldValidator =>
  (value: any, key?: string, allFormValues?: any, initialValues?: any) => {
    return key &&
      initialValues &&
      key in initialValues &&
      initialValues[key] !== value
      ? errorMessage
      : '';
  };

export const combineFieldValidators = (validators: FieldValidator[]) => {
  const combinedValidator: FieldValidator = (
    value: any,
    key?: string,
    allFormValues?: { [key: string]: any },
    initialValues?: { [key: string]: any }
  ) => {
    const errorStrings: string[] = [];
    for (let validator of validators) {
      const error = validator(value, key, allFormValues, initialValues);
      if (error) {
        errorStrings.push(error);
      }
    }
    return errorStrings.join(' ');
  };
  return combinedValidator;
};

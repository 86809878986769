import React from 'react';
import { observer } from 'mobx-react-lite';
import PouchDB from 'pouchdb';
// import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import Loading from 'components/common/Loading';

import { hexEncodeString } from 'utils/tools';
import WizardStore from 'state/common/WizardStore';

import { useUser } from 'hooks/useUser';
import { createDataDocument } from 'utils/db';
const dbUrl = 'https://dev.realtimeaid.live:6984';
const dbPrefix = 'userdb-';

// import { ActionField } from 'state/common/WizardStore/types' // <-- needs to be actionfieldcomponent not the field since this is used in the template

const Component = ({
  output,
  config,
  store,
}: {
  output?: boolean;
  config: {
    userDocType: 'person';
    usernameField?: string;
    passwordField?: string;
    buttonLabel?: string;
    errorRetry?: string;
    errorNotFoundMessage?: string;
    errorMessage?: string;
  };
  store: WizardStore;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<any>('');
  const { setUser } = useUser();

  React.useEffect(() => {
    const login = async () => {
      setIsLoading(true);
      const values = store.formValues.deserialize();
      const username = values[config.usernameField || 'username'];
      const password = values[config.passwordField || 'password'];
      try {
        const db = new PouchDB(
          `${dbUrl}/${dbPrefix}${hexEncodeString(username)}`,
          {
            skip_setup: true,
            auth: {
              username,
              password,
            },
          }
        );
        const userRes = await db.find({
          fields: ['_id'],
          selector: {
            type: {
              $eq: config.userDocType,
            },
            username: {
              $eq: username,
            },
          },
        });
        let userId = userRes.docs[0]?._id;
        if (!userId) {
          const res = await db.bulkDocs([createDataDocument({ username })]);
          userId = res[0].id || '';
        }
        const response = await db.allDocs({
          keys: [userId],
          include_docs: true,
          attachments: true,
          binary: true,
          conflicts: true,
        });
        const userDoc = response.rows[0].doc;

        setUser({
          userId: userId,
          userPerson: userDoc,
          userRemoteDb: db,
        });

        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setError(config.errorNotFoundMessage || err || true);
        setIsLoading(false);
      }
    };
    store.setSubmit(login);
  }, [setUser, setIsLoading, setError, store, config]);

  return output ? null : error ? (
    <Alert
      severity="warning"
      action={
        <Button
          color="inherit"
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setError(false);
          }}
        >
          {config.errorRetry}
        </Button>
      }
    >
      {typeof error === 'string' ? error : config.errorMessage}
    </Alert>
  ) : (
    <Button
      form={store.formValues.id}
      type="submit"
      variant="contained"
      color="secondary"
      disabled={isLoading}
    >
      {isLoading ? <Loading /> : config.buttonLabel}
    </Button>
  );
};

export default observer(Component);

import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import PhotoInput from 'components/common/PhotoInput';
import FieldOutput from 'components/Wizard/Form/FormSectionField/FieldOutput';
import { getMediaUrlSrc } from 'utils/browser';

const Component = ({
  output,
  value,
  onChange,
  name,
  label,
  error,
  helperText,
  form,
}: //

any) => {
  return output ? (
    <FieldOutput error={error} helperText={helperText}>
      <Avatar
        sx={{
          width: '200px',
          height: '200px',
          margin: (theme) => `${theme.spacing(2)} auto`,
          fontSize: (theme) => theme.typography.pxToRem(150),
          color: (theme) => theme.palette.background.paper,
        }}
        alt="photo"
        src={getMediaUrlSrc(value)}
      />
    </FieldOutput>
  ) : (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        {label && (
          <Typography color="textSecondary" variant="caption">
            {label}
          </Typography>
        )}
        <PhotoInput
          form={form}
          name={name}
          avatar
          enableCamera
          image={value}
          onImageChange={(photo: any) => onChange(photo)}
        />
      </Grid>
    </Grid>
  );
};

export default observer(Component);

import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const CaptureCanvas = ({ width, height, image, cropperRef }: any) => {
  const containerRef = useRef();

  return (
    <Box
      height="100%"
      width="100%"
      textAlign="center"
      position="relative"
      overflow="hidden"
      display="block"
      ref={containerRef}
    >
      <Cropper
        src={image}
        style={{
          margin: '16px auto',
          height: height ? height : 400,
          width: width ? width : '100%',
        }}
        // Cropper.js options
        aspectRatio={width && height ? width / height : 16 / 9}
        guides={false}
        ref={cropperRef}
      />
    </Box>
  );
};

export default CaptureCanvas;

import { makeObservable, observable, action, computed } from 'mobx';
import { createTheme as createMuiTheme, Direction } from '@mui/material/styles';

import {
  setDocumentDir,
  getScreenSize,
  configureTextDirection,
} from 'utils/browser';

import {
  paletteLight,
  paletteDark,
  componentsLight,
  componentsDark,
} from './overrides';

class ThemeStore {
  constructor() {
    makeObservable(this, {
      isDark: observable,
      direction: observable,
      screenSize: observable,
      activeTheme: computed,
      toggleDarkTheme: action,
      setDarkTheme: action,
      toggleDirection: action,
      onScreenResize: action,
    });
    window.addEventListener('resize', () => this.onScreenResize());
  }

  // OBSERVABLES................................................................
  isDark = true;
  direction = configureTextDirection() as Direction;
  screenSize = getScreenSize();

  // COMPUTEDS..................................................................
  get activeTheme() {
    return createMuiTheme({
      direction: this.direction,
      palette: this.isDark ? paletteDark : paletteLight,
      components: this.isDark ? componentsDark : componentsLight,
      typography: {
        subtitle1: {
          lineHeight: 1.5,
        },
        button: {
          lineHeight: 1.5,
        },
      },
      shape: {
        borderRadius: 8,
      },
    });
  }

  // ACTIONS....................................................................
  toggleDarkTheme = () => {
    this.isDark = !this.isDark;
  };

  setDarkTheme = (value: boolean) => {
    this.isDark = value;
  };

  toggleDirection = () => {
    this.direction = this.direction === 'ltr' ? 'rtl' : 'ltr';
    // set dir for native components
    setDocumentDir(this.direction);
  };

  onScreenResize = () => {
    this.screenSize = getScreenSize();
  };
}

export default ThemeStore;

import React from 'react';
import { observer } from 'mobx-react-lite';

import { ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { useTheme } from 'hooks/useTheme';

function Theme({ children }: any) {
  const { activeTheme, direction } = useTheme();
  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: direction === 'rtl' ? [prefixer, rtlPlugin] : [prefixer],
  });

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={activeTheme}>{children}</ThemeProvider>
    </CacheProvider>
  );
}

export default observer(Theme);

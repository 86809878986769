import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import WizardStore from 'state/common/WizardStore';
import WizardForm from 'components/Wizard/Form';

import Loading from 'components/common/Loading';

function Component({ store }: { store: WizardStore }) {
  const { isReady, init } = store;
  const [searchParams] = useSearchParams();
  React.useEffect(() => {
    if (!isReady) {
      init(searchParams);
    }
  }, [isReady, init, searchParams]);

  return isReady ? (
    <WizardForm store={store} />
  ) : (
    <Box display="flex" justifyContent="center" p={6}>
      <Loading />
    </Box>
  );
}

export default observer(Component);

// import React from 'react'
import Container, { ContainerProps } from '@mui/material/Container';

interface ExtendedContainerProps extends ContainerProps {
  pageConatiner?: boolean;
}

function Component({
  pageConatiner,
  maxWidth = 'xl',
  sx,
  ...props
}: ExtendedContainerProps) {
  // const auth = useAuth()
  // const navigate = useNavigate()
  return (
    <Container
      maxWidth={maxWidth}
      sx={
        pageConatiner
          ? {
              ...sx,
              pt: { xs: 2, sm: 3 },
              pb: { xs: 2, sm: 3 },
            }
          : sx
      }
      {...props}
    />
  );
}

export default Component;

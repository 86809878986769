import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

function Component({
  color = 'inherit',
  size = 24,
  ...props
}: CircularProgressProps) {
  return (
    <CircularProgress
      color={color}
      size={size}
      {...props}
      data-testid="loading"
    />
  );
}

export default Component;

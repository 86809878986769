import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useUserMedia } from './useUserMedia';
import { useTheme } from 'hooks/useTheme';

const DEFAULT_CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: 'user' },
};

const Camera = ({
  onCameraReady = (): void => {},
  onCapture = (image: any): any => image,
  onCameraDenied = (): void => {},
  capturing = false,
  captureOptions = DEFAULT_CAPTURE_OPTIONS,
}) => {
  const { screenSize } = useTheme();
  const canvasRef = useRef() as any;
  const videoRef = useRef() as any;
  const containerRef = useRef() as any;
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [canCapture, setCanCapture] = useState(true);

  const cWidth = containerRef.current?.offsetWidth || 0;
  const cHeight = containerRef.current?.offsetHeight || 0;
  const vWidth = videoRef.current?.videoWidth || 0;
  const vHeight = videoRef.current?.videoHeight || 0;

  const screenRatio = screenSize.width / screenSize.height;
  const videoRatio = vWidth / vHeight;
  const containerRatio = cWidth / cHeight;
  let offsetX = 0;
  let offsetY = 0;
  let maxWidth = undefined;
  let maxHeight = undefined;
  let width = undefined;
  let height = undefined;

  if (screenRatio) {
    // we need this component to rerender on screen changes - we might need the screen ratio for fullscreen stuff not sure, but for now we want to keep the observer
  }

  if (vWidth && vHeight && cWidth && cHeight) {
    if (containerRatio <= videoRatio) {
      if (vWidth < cWidth) {
        height = '100%';
        offsetX = Math.round((vHeight - cHeight) / 2);
        offsetY = 0;
      } else {
        height = '100%';
        offsetX = Math.round((cWidth - vWidth * (cHeight / vHeight)) / 2);
        offsetY = 0;
      }
    } else {
      if (vHeight < cHeight) {
        width = '100%';
        offsetX = 0;
        offsetY = Math.round((vWidth - cWidth) / 2);
      } else {
        width = '100%';
        offsetX = 0;
        offsetY = Math.round((cHeight - vHeight * (cWidth / vWidth)) / 2);
      }
    }
  }

  const mediaStream = useUserMedia(captureOptions, onCameraDenied);
  useEffect(() => {
    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
      videoRef.current.srcObject = mediaStream;
      onCameraReady();
    }
    if (capturing && canCapture) {
      setCanCapture(false);
      const onCaptureOverwriteName = (blob: any) => {
        if (blob instanceof Blob || blob instanceof File) {
          const file = new FormData() as any;
          file.append('image', blob, `camera_${Date.now()}`);
          onCapture(file.get('image'));
        } else {
          onCapture(null);
        }
      };
      const handleCapture = () => {
        const context = canvasRef.current.getContext('2d');
        context.drawImage(
          videoRef.current,
          0,
          0,
          vWidth,
          vHeight,
          0,
          0,
          vWidth,
          vHeight
        );

        canvasRef.current.toBlob(
          (blob: any) => onCaptureOverwriteName(blob),
          'image/jpeg',
          1
        );
      };
      handleCapture();
    }
  }, [
    onCameraReady,
    capturing,
    canCapture,
    setCanCapture,
    onCapture,
    vHeight,
    vWidth,
    mediaStream,
    videoRef,
  ]);

  function handleCanPlay() {
    setIsVideoPlaying(true);
    videoRef.current.play();
  }

  return (
    <Box
      height="100%"
      width="100%"
      position="relative"
      overflow="hidden"
      display="block"
      ref={containerRef}
    >
      <video
        style={{
          position: 'absolute',
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          width: width,
          height: height,
          top: offsetY,
          left: offsetX,
        }}
        ref={videoRef}
        hidden={!isVideoPlaying}
        onCanPlay={handleCanPlay}
        autoPlay
        playsInline
        muted
      />
      {!isVideoPlaying && (
        <Box
          justifyContent="center"
          display="flex"
          height="100%"
          alignItems="center"
          flexDirection="column"
        >
          <CircularProgress />
        </Box>
      )}

      <canvas
        ref={canvasRef}
        style={{ display: 'none' }}
        height={vHeight}
        width={vWidth}
      />
    </Box>
  );
};

export default observer(Camera);

// import React from 'react'
import { observer } from 'mobx-react-lite';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Container from 'components/common/Container';

import Logo from 'components/icons/Logo';

function Component() {
  // const navigate = useNavigate()
  return (
    <Container sx={{ pt: 8, pb: 3 }}>
      <Divider />
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        pt={3}
        spacing={1.5}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        sx={{ color: (theme) => theme.palette.text.disabled }}
      >
        <Logo />
        <Typography variant="caption">© 2023 RealtimeAid (gUG)</Typography>
        <Link variant="caption" href="https://realtimeaid.org/realtimeaid">
          About
        </Link>
      </Stack>
    </Container>
  );
}

export default observer(Component);

export type DataDocumentLink = {
  toType: string;
  toId: string;
  toFieldName?: string;
};

export type DataDocument = {
  _id?: string;
  _rev?: string;
  type?: string;
  template_id?: string;
  template_rev?: string;
  createdBy?: string;
  updatedBy?: string;
  createdOn?: string;
  updatedOn?: string;
  links?: DataDocumentLink[];
  [key: string]: any;
};

export const createDataDocument = (values: {
  [key: string]: any;
}): DataDocument => {
  const now = new Date().toJSON();
  let object: DataDocument = {
    _id: values._id,
    _rev: values._rev,
    type: values.type,
    ...(values.createdOn
      ? {
          createdOn: values.createdOn,
          updatedOn: now,
        }
      : { createdOn: now }),
  };
  let _attachments = {} as any;
  Object.keys(values).forEach((key) => {
    const value = values[key] as any;
    if (value instanceof Blob || value instanceof File) {
      _attachments[key] = {
        content_type: value.type,
        data: value,
      };
      object[key] = {};
    } else {
      object[key] = value;
    }
  });
  object._attachments = _attachments;
  return object;
};

export const getDataFromDataDocument = (
  doc?: DataDocument | PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta>
) => {
  let returnDoc = Object.assign({}, doc) as DataDocument;
  if (doc && doc._attachments) {
    Object.keys(doc._attachments).forEach((key: string) => {
      returnDoc[key] = doc._attachments[key].data;
    });
  }

  return returnDoc;
};

export const isValidCouchDBDatabaseName = (name: string): boolean => {
  const regex = new RegExp(`^[a-z][a-z0-9_$()+/-]{238}$`);
  return regex.test(name);
};

import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FieldOutput from 'components/Wizard/Form/FormSectionField/FieldOutput';
import PhotoInput from 'components/common/PhotoInput';
import { useTheme } from 'hooks/useTheme';
import { getMediaUrlSrc } from 'utils/browser';

const Component = ({
  output,
  value,
  onChange,
  // onValidate,
  name,
  label,
  // error,
  // helperText,
  form,
}: //

any) => {
  const { activeTheme: theme } = useTheme();
  return output ? (
    <FieldOutput>
      {value ? (
        <img
          style={{
            marginTop: label ? theme.spacing(1) : 0,
            width: '100%',
            height: '350px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 0,
            objectFit: 'cover',
          }}
          alt={label}
          src={getMediaUrlSrc(value)}
        />
      ) : undefined}
    </FieldOutput>
  ) : (
    <Grid container>
      <Grid item xs={12}>
        {label && (
          <Typography color="textSecondary" variant="caption">
            {label}
          </Typography>
        )}
        <PhotoInput
          form={form}
          name={name}
          enableCamera
          image={value}
          onImageChange={(photo: any) => onChange(photo)}
        />
      </Grid>
    </Grid>
  );
};

export default observer(Component);

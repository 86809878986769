// import React from 'react'
import { observer } from 'mobx-react-lite';

import WizardStore from 'state/common/WizardStore';
import { Template } from 'state/common/WizardStore/types';
import { DataDocument } from 'utils/db';

import FormWrapper from './FormWrapper';

function Component({
  db,
  template,
  data,
  afterSubmit,
}: {
  db?: PouchDB.Database;
  template: Template;
  data?: DataDocument;
  afterSubmit?: any;
}) {
  const store = new WizardStore({ db, template, data, afterSubmit });
  return <FormWrapper store={store} />;
}

export default observer(Component);

import React from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Container from 'components/common/Container';
import { useUser } from 'hooks/useUser';

function Component() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();
  return user.isAuthenticated ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <Container pageConatiner>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          <Typography pt={{ xs: 4, sm: 6 }} variant="h2">
            Localizing aid and getting rid of red tape
          </Typography>
          <Paper sx={{ p: 2, mt: 4 }}>
            <Typography variant="h5">
              We support grassroots initiatives to get rid of paperwork, to
              create trust and visibility.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} direction="row">
            <Button
              color="error"
              variant="outlined"
              size="large"
              onClick={() => {
                navigate('/join');
              }}
            >
              {'I want to support'}
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                navigate('/join');
              }}
            >
              {'I have a project'}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Component;

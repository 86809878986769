import { Template } from 'state/common/WizardStore/types';

const template: Template = {
  language: 'en',
  sections: [
    {
      title: 'Registration Details',
      label: 'Registration',
      maxWidth: 'xs',
      fields: [
        {
          label: 'Email',
          component: 'Text',
          name: 'email',
          isRequired: 'Email is required.',
          isEmail: 'Must be a valid email.',
          isUnchangeable: 'Email cannot be changed.',
          urlEnabled: true,
        },
        {
          component: 'TrueFalse',
          defaultValue: 'false',
          ignore: true,
          name: 'hasRequestedEmailVerification',
          label: 'User has requested verification',
          isUnchangeable: 'hasRequestedEmailVerification cannot be changed.',
          urlEnabled: true,
        },
        {
          if: {
            target: 'hasRequestedEmailVerification',
            condition: 'EQUALS',
            value: 'false',
          },
          actionComponent: 'GetEmailVerificaiton',
          config: {
            emailField: 'email',
            successField: 'hasRequestedEmailVerification',
            buttonLabel: 'Get verification token',
            requiredMessage: 'Verification token is required.',
            successMessage:
              'Please check your email for your verification token.',
            errorMessage: 'Something went wrong.',
            errorRetry: 'Try again',
          },
        },
        {
          if: {
            target: 'hasRequestedEmailVerification',
            condition: 'EQUALS',
            value: 'true',
          },
          component: 'TrueFalse',
          name: 'hasDb',
          label: 'User has a database',
          ignore: true,
          defaultValue: 'false',
          isUnchangeable: 'hasDb cannot be changed.',
        },
        {
          if: {
            target: 'hasRequestedEmailVerification',
            condition: 'EQUALS',
            value: 'true',
          },
          label: 'Verification token',
          component: 'Text',
          name: 'verificationToken',
          ignore: true,
          isRequired: 'Verification token is required.',
          urlEnabled: true,
        },
        {
          if: {
            target: 'hasRequestedEmailVerification',
            condition: 'EQUALS',
            value: 'true',
          },
          label: 'Username',
          component: 'Text',
          name: 'username',
          hidden: false,
          isRequired: 'Username is required.',
          isUnchangeable: 'Username cannot be changed.',
          urlEnabled: true,
        },
        {
          if: {
            target: 'hasRequestedEmailVerification',
            condition: 'EQUALS',
            value: 'true',
          },
          label: 'Set password',
          component: 'Text',
          name: 'password',
          ignore: true,
          isRequired: 'Password is required.',
          isPassword: 'Password must match criteria.',
        },
        {
          if: {
            target: 'hasRequestedEmailVerification',
            condition: 'EQUALS',
            value: 'true',
          },
          actionComponent: 'GetUserDb',
          config: {
            usernameField: 'username',
            passwordField: 'password',
            verificationTokenField: 'verificationToken',
            successField: 'hasDb',
            buttonLabel: 'Create account',
            successMessage:
              'Thank you for signing up. You can now login with the username and password you created.',
            errorMessage: 'Something went wrong.',
            errorRetry: 'Try again',
          },
        },
      ],
    },
  ],
};

export default template;

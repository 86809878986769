import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import { getMediaUrlSrc } from 'utils/browser';
import CaptureCanvas from 'components/common/CaptureCanvas';
import Camera from 'components/common/Camera';
import PhotoIcon from '@mui/icons-material/Photo';

import { useTheme } from 'hooks/useTheme';

const PhotoInput = ({
  name,
  image,
  onImageChange,
  placeholderIcon,
  enableCamera = false,
  avatar = false,
}: any) => {
  const [cameraOn, setCameraOn] = useState(false);
  const [crop, setCrop] = useState(false);
  const [key, setKey] = useState(0);
  const cropperRef = React.useRef() as any;
  const canvasRef = React.useRef() as any;
  const [canCapture, setCanCapture] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [error, setError] = useState(false);
  const { activeTheme: theme, isDark } = useTheme();

  const onInputChange = React.useCallback(
    (e: any) => {
      onImageChange(e.target.files[0]);
    },
    [onImageChange]
  );

  const handleCrop = React.useCallback(() => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const canvas = cropper.getCroppedCanvas();

    canvas.toBlob(
      (blob: any) => {
        onImageChange(blob);
        setCrop(false);
      },
      'image/jpeg',
      1
    );
  }, [onImageChange, cropperRef, setCrop]);

  const clearImage = React.useCallback(() => {
    onImageChange(null);
    setKey(key + 1); // <-- trigger update for input so the filelist is cleared
  }, [onImageChange, key]);

  const handleCapture = React.useCallback(
    (blob: any) => {
      if (blob) {
        onImageChange(blob);
      }
      setCameraOn(false);
      setCapturing(false);
      setCanCapture(false);
    },
    [onImageChange, setCameraOn, setCapturing, setCanCapture]
  );

  const handleCancel = React.useCallback(() => {
    setCanCapture(false);
    setCameraOn(false);
  }, [setCanCapture, setCameraOn]);

  const handleUseCamera = React.useCallback(() => {
    setCanCapture(false);
    setCameraOn(true);
  }, [setCanCapture, setCameraOn]);

  const handleCameraReady = React.useCallback(() => {
    setCanCapture(true);
  }, [setCanCapture]);

  const handleCameraDenied = React.useCallback(() => {
    setCameraOn(false);
    setError(true);
  }, [setCameraOn, setError]);

  const resize = React.useCallback(() => {
    createImageBitmap(image).then((bitmap) => {
      const ratio = image.size > 500000 ? 0.5 : 0.75;
      const context = canvasRef.current.getContext('2d');
      context.canvas.width = bitmap.width * ratio;
      context.canvas.height = bitmap.height * ratio;
      context.drawImage(
        bitmap,
        0,
        0,
        bitmap.width,
        bitmap.height,
        0,
        0,
        bitmap.width * ratio,
        bitmap.height * ratio
      );
      canvasRef.current.toBlob(
        (blob: any) => {
          onImageChange(blob);
        },
        'image/jpeg',
        ratio
      );
    });
  }, [canvasRef, image, onImageChange]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <input
        key={key}
        accept="image/*"
        style={{ display: 'none' }}
        id={name}
        onChange={onInputChange}
        type="file"
      />
      {avatar ? (
        <>
          {crop ? (
            <CaptureCanvas
              image={getMediaUrlSrc(image)}
              cropperRef={cropperRef}
              dragMode="move"
              width={200}
              height={200}
            />
          ) : (
            <Avatar
              sx={{
                width: '200px',
                height: '200px',
                margin: `${theme.spacing(2)}px auto`,
                fontSize: theme.typography.pxToRem(150),
                color: theme.palette.background.paper,
              }}
              src={getMediaUrlSrc(image)}
            >
              {enableCamera && cameraOn ? (
                <Camera
                  capturing={capturing}
                  onCameraDenied={handleCameraDenied}
                  onCameraReady={handleCameraReady}
                  onCapture={handleCapture}
                />
              ) : placeholderIcon && !image ? (
                placeholderIcon
              ) : null}
            </Avatar>
          )}
        </>
      ) : (
        <Card
          sx={{
            width: '100%',
            height: '350px',
            fontSize: theme.typography.pxToRem(150),
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.grey[isDark ? 600 : 400],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 0,
          }}
          elevation={0}
        >
          {crop ? (
            <CaptureCanvas
              image={getMediaUrlSrc(image)}
              cropperRef={cropperRef}
            />
          ) : enableCamera && cameraOn ? (
            <Camera
              capturing={capturing}
              onCameraDenied={handleCameraDenied}
              onCameraReady={handleCameraReady}
              onCapture={handleCapture}
            />
          ) : placeholderIcon && !image ? (
            placeholderIcon
          ) : !image ? (
            <PhotoIcon fontSize="inherit" />
          ) : (
            <CardMedia component="img" image={getMediaUrlSrc(image)} />
          )}
        </Card>
      )}
      {error && (
        <Button color="primary">{'Please enable camera permissions'}</Button>
      )}
      <div style={{ paddingTop: theme.spacing(1.5) }}>
        {image ? (
          <Grid container justifyContent="center">
            {crop && (
              <Grid item>
                <Button
                  component="button"
                  color="primary"
                  onClick={() => handleCrop()}
                >
                  {'Save'}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                component="button"
                color="primary"
                onClick={() => setCrop(!crop)}
              >
                {crop ? 'Cancel' : 'Crop'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                component="button"
                color="primary"
                onClick={() => resize()}
              >
                {'Compress'}
              </Button>
            </Grid>
            <Grid item>
              <Button component="button" color="primary" onClick={clearImage}>
                {'Remove'}
              </Button>
            </Grid>
          </Grid>
        ) : enableCamera && cameraOn ? (
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                component="button"
                color="primary"
                onClick={handleCancel}
                disabled={!canCapture}
              >
                {'Cancel'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                component="button"
                color="primary"
                onClick={() => setCapturing(true)}
                disabled={!canCapture}
              >
                {'Capture'}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <Grid item>
              <label htmlFor={name}>
                <Button component="span" color="primary">
                  {'Upload'}
                </Button>
              </label>
            </Grid>
            {enableCamera && (
              <Grid item>
                <Button
                  component="span"
                  color="primary"
                  onClick={handleUseCamera}
                  disabled={error}
                >
                  {'Use Camera'}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        {image && image.size && (
          <div>{`${Math.round(image.size / 1024)} kB`}</div>
        )}
        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </div>
    </div>
  );
};

export default PhotoInput;

import { makeObservable, observable, action } from 'mobx';
import { getBrowserSpeechRecognition } from 'utils/browser';

class SpeechRecognitionStore {
  constructor() {
    makeObservable(this as SpeechRecognitionStore, {
      ready: observable,
      output: observable,
      guess: observable,
      lastGuess: observable,
      onEnd: observable,
      onGuess: observable,
      init: action,
      onEndRecognition: action,
      onError: action,
      startRecognition: action,
      stopRecognition: action,
      setOutput: action,
      setGuess: action,
      setLastGuess: action,
    });
  }
  recognition = null as any;
  // OBSERVABLES................................................................
  ready = true;
  output = '';
  guess = '';
  lastGuess = '';
  onEnd = (output: any): void => output;
  onGuess = (guess: any): void => guess;

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  init = (lang: any) => {
    this.ready = false;
    if (this.recognition) {
      this.stopRecognition();
    }
    const BrowserSpeechRecognition = getBrowserSpeechRecognition();
    this.recognition = new BrowserSpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.lang = lang;

    this.recognition.onstart = () => {
      // console.log('SpeechRecognition.onstart');
    };

    this.recognition.onresult = (event: any) => {
      // console.log('SpeechRecognition.onresult');
      let guess = this.output;
      let output = this.output;
      for (let i = event.resultIndex; i < event.results.length; i++) {
        if (event.results[i].isFinal && event.results[i][0].confidence > 0) {
          output = output + event.results[i][0].transcript;
          guess = output;
          break;
        } else {
          guess = guess + event.results[i][0].transcript;
        }
      }
      this.setOutput(output);
      this.setGuess(guess);
    };

    this.recognition.onspeechend = () => {
      // console.log('SpeechRecognition.onspeechend');
    };

    this.recognition.onerror = (event: any) => {
      // console.log('SpeechRecognition.onerror');
      this.onError(event);
    };

    this.recognition.onaudiostart = () => {
      // console.log('SpeechRecognition.onaudiostart');
    };

    this.recognition.onaudioend = () => {
      // console.log('SpeechRecognition.onaudioend');
    };

    this.recognition.onend = () => {
      // console.log('SpeechRecognition.onend');
      this.onEndRecognition();
    };

    this.recognition.onnomatch = () => {
      // console.log('SpeechRecognition.onnomatch');
    };

    this.recognition.onsoundstart = () => {
      // console.log('SpeechRecognition.onsoundstart');
    };

    this.recognition.onsoundend = () => {
      // console.log('SpeechRecognition.onsoundend');
    };

    this.recognition.onspeechstart = () => {
      // console.log('SpeechRecognition.onspeechstart');
    };
  };

  onEndRecognition = () => {
    this.ready = true;
    this.onEnd(this.output);
  };

  onError = (e: any) => {
    console.log(e);
  };

  startRecognition = ({ onEnd, output = '', onGuess }: any) => {
    this.onEnd = onEnd;
    this.onGuess = onGuess;
    this.output = output ? output + ' ' : '';
    this.guess = output ? output + ' ' : '';
    this.lastGuess = '';
    this.recognition.start();
  };

  stopRecognition = () => {
    try {
      this.recognition.stop();
    } catch (DOMException) {
      // ...
    }
  };

  setOutput = (output: string) => {
    this.output = output;
    this.guess = '';
  };

  setGuess = (guess: string) => {
    this.guess = guess;
    this.onGuess(guess);
  };

  setLastGuess = (guess: string) => {
    this.lastGuess = guess;
  };
}

export default SpeechRecognitionStore;

import { useRoutes, Navigate } from 'react-router-dom';

import Layout from 'components/App/Layout';
import UserRegister from './UserRegister';
import UserDashboard from './UserDashboard';
import UserProfile from './UserProfile';
import Landing from './Landing';
import Login from './Login';

const Component = () => {
  return useRoutes([
    {
      path: '/*',
      element: <Layout />,
      children: [
        {
          index: true,
          path: '',
          element: <Landing />,
        },
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'dashboard',
          element: <UserDashboard />,
        },
        {
          path: 'join',
          element: <UserRegister />,
        },
        {
          path: 'user',
          element: <UserProfile />,
        },
        {
          path: 'user/:username',
          element: <UserProfile />,
        },
        // {
        //   path: 'public/:schemaId/:docId',
        //   element: <Public />,
        // },
        {
          path: '*',
          element: <Navigate to="/" replace />,
        },
      ],
    },
  ]);
};

export default Component;

import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Container from 'components/common/Container';
import FormSectionFields from '../FormSectionFields';

import WizardStore from 'state/common/WizardStore';
import { Section } from 'state/common/WizardStore/types';

function Component({
  section,
  store,
}: {
  section: Section;
  store: WizardStore;
}) {
  let conditionsMet = true;
  if ('if' in section) {
    conditionsMet =
      section.if?.condition === 'EQUALS'
        ? store.formValues.values.get(section.if.target) === section.if.value
        : true;
  }
  return conditionsMet ? (
    <Grid item xs={12}>
      <Container disableGutters maxWidth={section.maxWidth}>
        {section.title && (
          <Typography variant="h6" color="primary" mb={2}>
            {section.title}
          </Typography>
        )}
        <FormSectionFields fields={section.fields} store={store} />
      </Container>
    </Grid>
  ) : null;
}

export default observer(Component);

import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';

import Routes from './Routes';
import Theme from './Theme';

function App() {
  return (
    <BrowserRouter>
      <Theme>
        <CssBaseline />
        <Routes />
      </Theme>
    </BrowserRouter>
  );
}

export default App;
